import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { observable, action } from 'mobx';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
import { api } from '../../../utils';
import { scrollToAnchor } from '../../../utils/tools';
import { MainImgsStore, ProductInfoStore, charaReg, charaRegGlobal, getImageInfo, getImageUrl, dealUnlegalImg, dealOversizeImg } from '../../shelvesBase';
import type { styleImgRes } from '../interface';
import { AnchorStore } from './anchor/store';
import { SizeColorStore } from './priceStock/saleInfo/sizeColorStore';
import { SingleUploadImgStore } from './singleUploadImg/store';

export class StepTwoStore {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public mainImgsStore = new MainImgsStore();

  @observable public singleUploadImgStore = new SingleUploadImgStore(this);

  @observable public sizeColorStore = new SizeColorStore(this);

  @observable public productInfoStore = new ProductInfoStore(this);

  @observable public anchorStore = new AnchorStore(this);

  @observable public formRef = React.createRef<FormInstance>();

  @observable public goodsServiceMap = {};// 商品类型对应承诺发货时间选项映射

  @observable public twoPiecesDiscountRule = {
    ifMustTwoPiecesDiscount: false,
    recommendTwoPiecesDiscount: 0,
    maxTwoPiecesDiscount: 0,
    minTwoPiecesDiscount: 0,
  };// 满两件折扣规则

  @observable public shipLimitOptions = [];

  @observable public logisticsTemplateOptions = [];// 运费模版选项

  @observable public productTypeOptions = [];// 商品类型选项

  @observable public countryOpitons = [];// 货源地选项

  @observable public minSalePrice = 0;// 最小的商品参考价

  @observable public maxSalePrice = 1;// 最大商品参考价

  @observable public deliveryTimeOptions = [];// 发售时间选项

  @observable public productHasFilled = 0;// 商品标题已填字数

  @observable public goodsProps = [];// 商品属性

  @observable public fullScreenPics = [];// 商品长图

  @observable public materialPics = [];// 商品素材图

  @observable public skuPreSale = false;// 是否为sku预售

  @observable public resultVisilbe = false;// 上架展示结果

  @observable public submitResult = '';// 成功或失败

  @observable public listingMode = 0;// 上架或草稿箱

  @observable public shopName = '';// 上架店铺名称

  @observable public goodsId = 0;

  @observable public errorMessage = '';// 错误信息

  @observable public linkedProps = {};// 级联属性

  @observable public linkPropsKeys = [];// 级联熟悉keys

  @observable public closeResultModal = () => {
    this.resultVisilbe = false;
  };

  @observable public closeTab = () => {
    window.top.egenie.closeTab(this.parent.pageId);
  };

  // 商品类型修改
  @action public handleProductTypeChange = (val) => {
    // 处理承诺发货时间
    this.shipLimitOptions = this.goodsServiceMap[val].shipmentLimitSecondList.slice();// 浅拷贝一份

    // 允许当日发货
    if (this.goodsServiceMap[val].deliveryOneDayRule) {
      this.shipLimitOptions.unshift(0);
    }
  };

  // 商品标题输入
  @action public handleProductChange = (e) => {
    const productName = e.target.value.replace(charaRegGlobal, 'rr');
    this.productHasFilled = productName.length < 60 ? productName.length : 60;
    if (productName.length > 60) {
      const goodsName = this.sliceStrLen(e.target.value, 60);
      this.formRef?.current.setFieldsValue({ goodsName });
    }
  };

  // 截取字符串长度
  private sliceStrLen = (str: string, length: number): string => {
    let len = 0;
    const resArr = [];
    for (let i = 0; i < str.length; i++) {
      if (charaReg.test(str[i])) {
        len += 2;
      } else {
        len++;
      }

      if (len <= length) {
        resArr.push(str[i]);
      }
    }
    return resArr.join('');
  };

  // 处理预售类型变化
  @action public handlePresellChange = (val) => {
    this.sizeColorStore.showSKUPresaleTime = val === 3;
    this.formRef.current.setFieldsValue({ deliveryType: 172800 });
  };

  // 初始化数据
  @action public initFormData = async(data) => {
    const { goodsBase, goodsProps, goodsPic, goodsSkuList, saleProps, goodsPropPicList, platformExtends, linkedPropsMap } = data;

    this.linkedProps = linkedPropsMap;
    this.dealGoodsProps(goodsProps, linkedPropsMap);
    this.goodsProps = goodsProps;

    // 年份季节倒序排列
    this.goodsProps.forEach((item) => {
      // 补丁代码:类目为上市时节 按照最新年份倒序排列
      if (item.id === '1906') {
        item.checkOptions = _.sortBy(item.checkOptions, (o) => Number(o.value.slice(0, 4))).reverse();
      }
    });

    const { goodsName, salePrice } = goodsBase;
    this.productHasFilled = goodsName.length;
    const { preSale, operateType, secondHand, goodsType, deliveryOneDay, shipmentLimitSecond, refundable, countryId, costTemplateId, twoPiecesDiscount, preSaleTime, groupPreSale, skuPreSale, folt } = platformExtends;

    // 处理预售
    let presellType: number;
    
    // 定时预售
    if (preSaleTime) {
      presellType = 1;
    }

    // 时段预售
    if (groupPreSale) {
      presellType = 2;
    }

    // sku预售
    if (skuPreSale) {
      presellType = 3;
    }

    // 非预售
    if (!skuPreSale && !preSaleTime && !preSale && !groupPreSale) {
      presellType = 0;
    }

    let deliveryType: number;

    // 承诺发货时间(当日发)
    if (deliveryOneDay) {
      deliveryType = 0;
    }

    if (shipmentLimitSecond) {
      deliveryType = shipmentLimitSecond;
    }

    // 时段预售换算成天
    if (presellType === 2) {
      deliveryType = shipmentLimitSecond / (3600 * 24);
    }

    // 初始化商品标题
    const productName = goodsName.replace(charaRegGlobal, 'rr');
    this.productHasFilled = productName.length;
    
    this.formRef.current?.setFieldsValue({
      salePrice,
      goodsName,
      folt,
      operateType: operateType ?? 0,
      secondHand: secondHand ?? false,
      goodsType: goodsType ?? 1,
      presellType,
      deliveryType,
      twoPiecesDiscount: twoPiecesDiscount || this.twoPiecesDiscountRule.recommendTwoPiecesDiscount,
      refundable: true,
      preSaleTime: preSaleTime ? moment(preSaleTime) : null,
      costTemplateId: costTemplateId ?? this.logisticsTemplateOptions[0].value,
      countryId: countryId || '',
    });

    if (deliveryType === undefined) {
      this.handlePresellChange(presellType);
    }

    // 承诺发货时间展示
    this.handleProductTypeChange(goodsType);
    const { fullScreenPics, materialPics } = goodsPic;

    // 处理主图
    try {
      for (let i = 0; i < goodsPic.mainPics.length; i++) {
        goodsPic.mainPics[i] = await this.dealMainImg(goodsPic.mainPics[i]);
      }
  
      // 回写主图
      this.mainImgsStore.writeBackMainImgs(goodsPic);
    } catch (e) {
      console.log('处理主图出错', e);
    }
    
    // 处理商品详情图
    try {
      for (let i = 0; i < goodsPic.detailPics.length; i++) {
        goodsPic.detailPics[i] = await this.dealDetailImg(goodsPic.detailPics[i]);
      }
  
      this.productInfoStore.writeBackDetailImgs(goodsPic);// 回写商品详情
    } catch (e) {
      console.log('处理详情图出错', e);
    }

    // 处理白底图
    try {
      if (materialPics[0]) {
        materialPics[0] = await this.dealMaterialImg(materialPics[0]);
      }
    } catch (e) {
      console.log('处理白底图出错', e);
    }

    // 处理长图
    try {
      if (fullScreenPics[0]) {
        fullScreenPics[0] = await this.dealLongImg(fullScreenPics[0]);
      }
  
      // 商品长图
      this.fullScreenPics = fullScreenPics.length > 0 ? fullScreenPics.map((item) => ({
        key: nanoid(),
        url: item,
      })) : [];
    } catch (e) {
      console.log('处理长图出错', e);
    }

    // 处理白底图
    try {
      if (materialPics[0]) {
        materialPics[0] = await this.dealMaterialImg(materialPics[0]);
      }
  
      // 商品素材图
      this.materialPics = materialPics.length > 0 ? materialPics.map((item) => ({
        key: nanoid(),
        url: item,
      })) : [];
    } catch (e) {
      console.log('处理白底图出错', e);
    }

    // 处理颜色图片
    try {
      for (let i = 0; i < goodsPropPicList.length; i++) {
        goodsPropPicList[i].url = await this.dealColorImg(goodsPropPicList[i].url);
      }
    } catch (e) {
      console.log('处理颜色图出错', e);
    }
   
    // 初始化颜色尺码
    this.sizeColorStore.initData(saleProps, goodsPropPicList, goodsSkuList);
  };

  // 处理商品属性联动关系
  @action public dealGoodsProps = (goodsProps, linkedPropsMap) => {
    const linkPropsKeys = Object.keys(linkedPropsMap);
    this.linkPropsKeys = linkPropsKeys;
    for (let i = 0; i < goodsProps.length; i++) {
      if (linkPropsKeys.some((keyItem) => keyItem.split('_')[0] === goodsProps[i].id)) {
        if (goodsProps[i].value) {
          const propsItem = linkedPropsMap[`${goodsProps[i].id}_${goodsProps[i].value}`];
          goodsProps.splice(i + 1, 0, propsItem);
        }
      }
    }
  };

  // 提交
  @action public submitInfo = async() => {
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('priceStock');
      return;
    }

    await this.anchorStore.getIncomplete();

    const allTitles = this.anchorStore.allTitles;
    const item = Object.keys(allTitles);
    for (let i = 0; i < Object.keys(allTitles).length; i++) {
      if (allTitles[item[i]].hasFilled < allTitles[item[i]].requiredNum) {
        scrollToAnchor(allTitles[item[i]].id);
        return;
      }
    }

    const saveData = await this.releaseFormInfo();

    this.parent.loading = true;

    // 上架
    this.resultVisilbe = true;
    this.submitResult = 'inProgress';

    request<BaseData<any>>({
      url: '/api/gms/goods/platform/pdd/goodsPutonShelf',
      method: 'POST',
      data: saveData,
      timeout: 180000,
      
    }).then((res) => {
      const { shopName, upStatus, reason, platformId } = res.data;
      this.shopName = shopName;
      this.errorMessage = reason;
      this.goodsId = platformId;
      this.submitResult = upStatus ? 'Successful' : 'Failed';
    })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 获取表单内容
  private releaseFormInfo = async() => {
    const data = await this.formRef.current.getFieldsValue();
    const { goodsName, salePrice, costTemplateId, goodsType, operateType, refundable, secondHand, countryId, presellType, deliveryType, twoPiecesDiscount, preSaleTime, folt } = data;
    const { gmsGoodsId, categoryId, categoryName, categoryIds } = this.parent;

    // 准备goodsBase
    const goodsBase = {
      goodsCategoryId: categoryId,
      goodsCategoryIds: categoryIds,
      goodsCategoryFullName: categoryName.replace(/>/g, ','),
      goodsName,
      salePrice, // 一口价
    };

    const goodsPicVo = {
      mainPics: this.mainImgsStore.mainPics.map((item) => item.url),
      fullScreenPics: this.fullScreenPics.length > 0 ? this.fullScreenPics.map((item) => item.url) : [],
      materialPics: this.materialPics.length > 0 ? this.materialPics.map((item) => item.url) : [],
      detailPics: this.productInfoStore.imgs.length > 0 ? this.productInfoStore.imgs.map((item) => item.url) : [],
    };

    const goodsSkuSetOnShelfRequestList = this.sizeColorStore.goodsSkuList;
    const checkedColorList = (this.sizeColorStore.colorList).filter((item) => item.checked);
    const { colorPropName } = this.sizeColorStore;
    const goodsPropPicVos = checkedColorList.map((item) => ({
      url: item.url,
      properties: `${colorPropName}:${item.name}`,
      smallPicUrl: '',
    }));

    // 商品属性
    let goodsProps = this.goodsProps.map((cur) => {
      const { id, type, name } = cur;
      const values = data;
      let eachValue;
      if (type !== 'INPUT') {
        eachValue = typeof values[id] === 'object' ? (values[id] && values[id].join(',')) : values[id];
      } else {
        eachValue = values[id];
      }
      return {
        id,
        name,
        value: eachValue,
      };
    });

    // 过滤掉没有值的商品属性
    goodsProps = goodsProps.filter((item) => {
      return item.value;
    });

    // 承诺发货时间
    let deliveryOneDay = 0;
    if (deliveryType === 0) {
      deliveryOneDay = 1;
    }

    const platformExtends = {
      costTemplateId,
      goodsType,
      operateType,
      refundable,
      folt,
      secondHand,
      twoPiecesDiscount,
      goodsProps,
      catId: categoryId,
    };

    // deliveryOneDay和shipmentLimitSecond互斥
    if (deliveryOneDay) {
      Object.assign(platformExtends, { deliveryOneDay });
    } else {
      Object.assign(platformExtends, { shipmentLimitSecond: deliveryType });
    }

    // 定时预售
    if (presellType === 1) {
      Object.assign(platformExtends, {
        preSale: true,
        preSaleTime: preSaleTime?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    // 时段预售
    if (presellType === 2) {
      Object.assign(platformExtends, {
        groupPreSale: 1,
        shipmentLimitSecond: deliveryType * 24 * 3600,
      });
    }

    // sku预售
    if (presellType === 3) {
      Object.assign(platformExtends, { skuPreSale: 1 });
    }

    // 海外进口的话必须传货源地
    if (goodsType !== 1) {
      Object.assign(platformExtends, { countryId });
    }

    // 上架或放入草稿箱
    this.listingMode = operateType;

    const saveData = {
      gmsGoodsId,
      goodsBase,
      goodsPicVo,
      goodsPropPicVos,
      goodsSkuSetOnShelfRequestList,
      platformExtends,
    };

    return saveData;
  };

  // 保存
  @action public saveInfo = async() => {
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('priceStock');
      return;
    }
    this.parent.loading = true;
    const saveData = await this.releaseFormInfo();
    request<BaseData>({
      url: '/api/gms/goods/platform/pdd/saveGoodsPutOnShelf',
      method: 'POST',
      data: saveData,
    }).then((res) => {
      message.success(res.data || '保存成功');
    })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 处理属性变化
  public formItemOnChange = (key, val, index) => {
    if (val) {
      const mapKey = `${key}_${val}`;
      if (this.linkPropsKeys.includes(mapKey)) {
        // 有联动关系的项纪录上一次的值
        const changeIndex = this.goodsProps.findIndex((item) => item.id === key);
        this.goodsProps[changeIndex].lastVal = val;
    
        const addPropsItem = this.linkedProps[mapKey];
        if (this.goodsProps.find((item) => item.id === addPropsItem.id)) {
          this.goodsProps.splice(index + 1, 1, addPropsItem);
          this.formRef.current?.setFieldsValue({ [addPropsItem.id]: '' });
          this.formItemOnChange(addPropsItem.id, '', index + 1);
        } else {
          this.goodsProps.splice(index + 1, 0, addPropsItem);
        }
      }
    } else {
      const deletePropsItem = this.goodsProps.splice(index + 1, 1);

      // 为性能考虑 暂不递归
      const mapKey = `${deletePropsItem[0].id}_${deletePropsItem[0].lastVal}`;
      if (this.linkPropsKeys.includes(mapKey)) {
        const childItem = this.linkedProps[mapKey];
        const childIndex = this.goodsProps.findIndex((item) => item.id === childItem.id);
        if (childIndex !== -1) {
          this.goodsProps.splice(childIndex, 1);
        }
      }
    }
  };

  // 获取主图款式库图片
  @action public getPosGoodsGroupPics = (): void => {
    request<BaseData<styleImgRes[]>>({
      url: api.getPosGoodsGroupPics,
      method: 'POST',
      data: { gmsGoodsId: this.parent.gmsGoodsId },
    }).then(async(res) => {
      const styleList = res.data[0].mainPicUrls;
      for (let i = 0; i < styleList.length; i++) {
        const url = await this.dealMainImg(styleList[i]);
        this.mainImgsStore.styleImgLists.push({
          id: nanoid(),
          imgId: nanoid(),
          url,
        });
      }
    })
      .catch(() => {
        this.mainImgsStore.styleImgLists = [];
      });
  };

  // 处理主图尺寸
  private dealMainImg = async(url) => {
    const { ImageWidth, ImageHeight, Format, FileSize } = await getImageInfo(url);

    let resizeWidth: number;
    let resizeHeight: number;

    // 处理主图（最小宽度480，比例1:1或3:4）
    resizeWidth = Number(ImageWidth.value) < 480 ? 480 : Number(ImageWidth.value);
    resizeHeight = Number(ImageHeight.value);

    // 比例不合适裁剪
    if (resizeWidth / resizeHeight !== 1 && resizeWidth / resizeHeight !== 0.75) {
      if ((resizeWidth / resizeHeight) < 0.75) {
        resizeWidth = resizeHeight * 0.75;
      } else {
        const longer = Math.max(resizeWidth, resizeHeight);
        resizeHeight = longer;
        resizeWidth = longer;
      }
    }
   
    let newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);
    newUrl = dealUnlegalImg(newUrl, Format.value);

    const fileSize = Number(FileSize.value) / 1024 / 1024;
    if (fileSize > 1) {
      newUrl = dealOversizeImg(newUrl, Format.value);
    }
    return newUrl;
  };

  // 处理商品详情图尺寸
  private dealDetailImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);
    let resizeWidth: number;
    let resizeHeight: number;
    
    // 比480还小 取480
    resizeWidth = Number(ImageWidth.value) < 480 ? 480 : Number(ImageWidth.value);
    
    // 比100还大 取1200
    resizeWidth = Number(ImageWidth.value) > 1200 ? 1200 : resizeWidth;

    // eslint-disable-next-line prefer-const
    resizeHeight = Number(ImageHeight.value) > 1500 ? 1500 : Number(ImageHeight.value);

    let newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);
    newUrl = dealUnlegalImg(newUrl, Format.value);
    return newUrl;
  };

  // 处理颜色图尺寸
  private dealColorImg = async(url) => {
    const { ImageWidth, ImageHeight, Format, FileSize } = await getImageInfo(url);
    let resizeWidth: number;
    let resizeHeight: number;

    // 比480小取480
    // eslint-disable-next-line prefer-const
    resizeWidth = Number(ImageWidth.value) < 480 ? 480 : Number(ImageWidth.value);
    // eslint-disable-next-line prefer-const
    resizeHeight = resizeWidth;

    let newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);
    newUrl = dealUnlegalImg(newUrl, Format.value);

    const fileSize = Number(FileSize.value) / 1024 / 1024;
    if (fileSize > 1) {
      newUrl = dealOversizeImg(newUrl, Format.value);
    }

    return newUrl;
  };

  // 处理白底图
  private dealMaterialImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);
    let newUrl = getImageUrl(url, 480, 480, ImageWidth, ImageHeight);
    newUrl = dealUnlegalImg(newUrl, Format.value);
    return newUrl;
  };

  // 处理长图
  private dealLongImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);
    
    let newUrl = getImageUrl(url, 400, 600, ImageWidth, ImageHeight);
    newUrl = dealUnlegalImg(newUrl, Format.value);
    return newUrl;
  };
}

